import { LOCALE_ID, Component, Inject, OnInit } from '@angular/core';
import {
  DatePipe, 
  FormStyle, 
  getLocaleDayNames, 
  TranslationWidth } from '@angular/common';

import { CustomDate } from '../model/custom_date';
import { Appointment } from '../model/appointment';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit{
  
  /*
  * Appointments Widget
  */
  week:CustomDate[] = [];
  weekDaysNames: readonly string[] = [];
  myDate: Date = new Date();
  hoursTimeline:string[] = [];

  appointment: Appointment = {
    doctor: null,
    clinic: null,
    patient: null,
    date: new Date(),
    hour: "-",
    duration: "60 min",
    information: "",
    blocked: false,
    observations: "",
    confirmed: false,
    arrived: false,
    finalized: false
  }

  constructor(
    @Inject(LOCALE_ID) public locale: string,
    private datePipe: DatePipe) {
      /*
      * Appointments Widget
      */
      this.getCurrentWeekDays();
      this.hoursTimeline = [
        "08:00", "09:00", "10:00", "11:00", "12:00", "13:00", "14:00", "15:00",
        "16:00", "17:00", "18:00", "19:00", "20:00", "21:00"
      ]
  }

  ngOnInit(): void {
    console.log("newest version!");
  }

  /*
  * Appointments Widget
  */
  getCurrentWeekDays() {
    this.weekDaysNames = getLocaleDayNames(
      this.locale,
      FormStyle.Standalone,
      TranslationWidth.Abbreviated
    );


    const today = new Date();
    const currentDay = today.getDay();

    // Calculate the first day of the week (Sunday) and adjust for the current day
    const firstDay = new Date(today);
    firstDay.setDate(today.getDate() - currentDay);

    // Populate the array with dates for the current week
    for (let i = 0; i < 7; i++) {
      const day = new Date(firstDay);
      day.setDate(firstDay.getDate() + i);
      this.week.push(new CustomDate(this.weekDaysNames[i], day));
    }
  }

  isHighlighted(date:CustomDate):String {
    return date.weekDayNo.getDay() === this.myDate.getDay() ? "day highlighted" : "day";
  }
}

/*
*TODO: 
  - handle appointment display on calendar (grid layout based on hour);
  - verify hasArrived / hasWarning / hasXray css classes;
*/
