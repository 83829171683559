import { Component, OnInit  } from '@angular/core';
import { Router } from '@angular/router';
import { SwUpdate, VersionEvent } from '@angular/service-worker';
import { filter } from 'lodash';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit{
  title:String = 'MedicCopilot';

  constructor (private router: Router,
    private swUpdate: SwUpdate) {
      this.checkForUpdate();
  }

  ngOnInit(): void {}

  checkForUpdate() {
    if (this.swUpdate.isEnabled) {
      this.swUpdate.versionUpdates
      .subscribe((event) => {
        if(event.type == 'VERSION_READY'){
          if(confirm('A new verison of the app is available. Would you like to update?')) {
            window.location.reload();
          }
        }
      })
    }
  }
}
